import React, { useReducer, useEffect } from "react"
import InsuranceApi from "../api/insurance.api";
import { EventEmitterService, EventKey } from "../services/EventEmitterService";
import { SessionService } from "../services/SessionService";
import { isToday, isUK } from "../services/utils";
import { ONGOING_PURCHASE } from "./InsuracePurchaseContext";
import { COVERAGE_TYPES } from "../models/insurance-models/CoverageType";
import { FirebaseService, FirebaseConfigKey } from "../services/FirebaseService";
import { WorldWideOption } from "../models/insurance-models/WorldWideOption";
import { UserProfileContextStore ,ACTIONS} from "./UserProfileContext"
import { NUMBER_OF_DRONES_QUESTION_ANSWERS } from "../Constants";
import generalApi from "../api/general.api";
import { PurchaseFlowSessionService } from "../services/PurchaseFlowStorageService";

export const OFFERS_HISTORY_KEY = 'offers_ongoing_purchase'

export const OFFERS_ACTIOS = {
    ADD_OPERATOR: 'ADD_OPERATOR',
    DELETE_OPERATOR: 'DELETE_OPERATOR',
    EDIT_OPERATOR: 'EDIT_OPERATOR',
    SET_LIABILITY_SPECIAL_PRICE: 'SET_LIABILITY_SPECIAL_PRICE',
    SET_INDOOR_COVERAGE: 'SET_INDOOR_COVERAGE',
    CHANGE_NUMBER_OF_DRONES_OPERATE: 'CHANGE_NUMBER_OF_DRONES_OPERATE',
    RESET_START_TIME: 'RESET_START_TIME',
    SET_SELECTIONS: 'SET_SELECTIONS'
}

const resetableState = {
    intendedUsesLoaded: false,
}

export const FLOW_TYPES = {
    REGULAR: 'regular',
    FLEET: 'fleet'
}

const defaultState = {
    maximumUasInAir: 1,
    numberOfDronesOperate:0,
    numberOfDronesText: NUMBER_OF_DRONES_QUESTION_ANSWERS[0].value,
    monthlyOffers: [],
    annualOffers: [],
    operators: [],
    intendedUses: [],
    indoorCoverage: { included: true, description: '' },
    fleetInformation: {
        fleetSize: 6,
        yearlySize: 6,
        fleetIsPublishable: undefined,
        fleetProceduresControl: undefined
    },
    fleetErrors: {},
    worldWideCoverage: { territorialLimits: WorldWideOption.Default, territorialLimitsDescription: "" },
    personalInjuryCoverage: FirebaseService.getArray(FirebaseConfigKey.PERSONAL_INJURY_COVERAGE)[0],
    medicalExpense: FirebaseService.getArray(FirebaseConfigKey.MEDICAL_EXPENSES)[0],
    coverageType: isUK() ? COVERAGE_TYPES.LIABILITY : COVERAGE_TYPES.COMBINED,
    subscriptionStartTime:  new Date(),
    liabilitySpecialPrice: { enabled: "no", custom_premium: 0},
    changeStartingDate: (date) => {throw "Not Implemented Exception"},
    changeMaximumUAS: (maximumUasInAir) => {throw "Not Implemented Exception"},
    changeCoverageType: (type) => {throw "Not implemented"},
    changePersonalInjury: (value) => {throw "Not implemented"},
    changeMedicalExpenseLimit: (value) => {throw "Not implemented"},
    changeNumberOfDronesUserOperate: (value) => {throw "Not implemented"},
    loadQuote: (quote) => {throw "Not implemented"},
    flipWorldWideCoverage: (limits, description) => {throw 'Not implemented'},
    flowType: FLOW_TYPES.NORMAL,
    ...resetableState
}

const calculateState = (state, action) => {
    switch (action.type) {
        case 'RESET': 
            return defaultState
        case 'MAXIMUM_UAS_CHANGE':
            return {...state, maximumUasInAir: action.maximumUasInAir}
        case 'CHANGE_NUMBER_OF_DRONES_OPERATE':
            return {...state, numberOfDronesOperate: action.value, numberOfDronesText: action.numberOfDronesText}
        case 'OFFERS_LOADED':
            return {...state, monthlyOffers: action.monthlyOffers, annualOffers: action.annualOffers}
        case 'PERSONAL_INJURY_CHANGE':
            return {...state, personalInjuryCoverage: Number.parseInt(action.value)}
        case 'MEDICAL_EXPENSE_LIMIT_CHANGE':
            return {...state, medicalExpense: Number.parseInt(action.value)}
        case 'SET_WORLD_WIDE_COVERAGE_LIMITS':
            return {...state, worldWideCoverage: { territorialLimits: action.data.limits,
                 territorialLimitsDescription: action.data.desc }}
        case 'CHANGE_STARTING_DATE':
            if (isToday(action.startingDate)){
                return {...state, subscriptionStartTime: new Date()}
            }
            else if(action.startingDate && action.startingDate > new Date() && !isToday(action.startingDate))
                return {...state, subscriptionStartTime: action.startingDate}
            else
                return state
        case 'LOAD_QUOTE':
            let startingDate = new Date()
            if(action.data.start_time) {
                let requestedStartingTime = new Date(action.data.start_time)
                if(requestedStartingTime > new Date() && !isToday(requestedStartingTime)) 
                    startingDate = requestedStartingTime
            } 
            return {...state, 
                    subscriptionStartTime: startingDate,
                     maximumUasInAir: action.data.maximumUasInAir || action.data.maximum_uas_in_air || 1, 
                     coverageType: action.data.coverage_type || action.data.coverageType || COVERAGE_TYPES.COMBINED,
                     operators: action.data.operators || action.data.operators || [],
                     worldWideCoverage: { territorialLimits: action.data.territorial_limits, territorialLimitsDescription: action.data.territorial_limits_description },
                     personalInjuryCoverage: Number.parseInt(action.data.personal_injury_limit),
                     medicalExpense: Number.parseInt(action.data.medical_expense_limit),
                     customPremium: Number.parseInt(action.data.custom_premium),
                     indoorCoverage: { included: action.data.indoor_coverage, description: action.data.indoor_coverage_description },
                     customPremiumToken: action.data.custom_premium_token
                    }
        case 'SET_COVERAGE_TYPE':
            return {...state, coverageType: action.data}
        case OFFERS_ACTIOS.SET_INDOOR_COVERAGE: 
            return {...state, indoorCoverage: action.data}
        case OFFERS_ACTIOS.RESET_START_TIME: 
            return {...state, subscriptionStartTime: new Date()}
        case OFFERS_ACTIOS.SET_LIABILITY_SPECIAL_PRICE: 
            return {...state, liabilitySpecialPrice: action.data}
        case OFFERS_ACTIOS.ADD_OPERATOR:
                return {...state, operators: [...state.operators, action.data]}
        case OFFERS_ACTIOS.EDIT_OPERATOR:
            return {...state, operators: state.operators.map((item, index) => index === action.data.index ? action.data.operator : item)}
        case OFFERS_ACTIOS.DELETE_OPERATOR: 
            return {...state, operators: state.operators.filter((item, index) => index != action.data)}
        case OFFERS_ACTIOS.SET_SELECTIONS: 
            return {...state, ...action.data}
        case 'SET_FLEET_FILE':
            return {...state, fleetInformation: {...state.fleetInformation, fleetFile: action.data}}
        case 'SET_FlEET_SIZE':
            return {...state, fleetInformation: {...state.fleetInformation, fleetSize: action.data}}
        case 'SET_YEARLY_FlEET_SIZE':
            return {...state, fleetInformation: {...state.fleetInformation, yearlySize: action.data}}
        case 'SET_FLEET_INTENDED_USE':
            return {...state, fleetInformation: {...state.fleetInformation, fleetIntendedUse: action.data}}
        case 'SET_FLEET_ERRORS':
            return {...state, fleetErrors: action.data}
        case 'LOAD_INTEDED_USES':
            return {...state, intendedUses: action.data, intendedUsesLoaded: true}
        case 'SET_REGULAR_FLOW':
            return {...state, 
                flowType: FLOW_TYPES.REGULAR,
                worldWideCoverage: { territorialLimits: WorldWideOption.Default, territorialLimitsDescription: "" },
                personalInjuryCoverage: FirebaseService.getArray(FirebaseConfigKey.PERSONAL_INJURY_COVERAGE)[0],
                medicalExpense: FirebaseService.getArray(FirebaseConfigKey.MEDICAL_EXPENSES)[0],
                coverageType: COVERAGE_TYPES.COMBINED,
                numberOfDronesOperate: 0
            }
        case 'SET_FLEET_FLOW':
            return {...state, 
                flowType: FLOW_TYPES.FLEET,
                worldWideCoverage: { territorialLimits: WorldWideOption.Default, territorialLimitsDescription: "" },
                personalInjuryCoverage: FirebaseService.getNumber(FirebaseConfigKey.DEFAULT_LIABILITY),
                medicalExpense: 5000,
                coverageType: COVERAGE_TYPES.COMBINED,
                numberOfDronesOperate: 2
            }
        case 'SET_FLEET_PUBLISHABLE_YES_NO':
            return {...state, fleetInformation: {...state.fleetInformation, fleetIsPublishable: action.data}}
        case 'SET_PI_COVERAGE':
            return {...state, personalInjuryCoverage: action.data}
        case 'SET_FLEET_PROCEDURES_CONTROL_YES_NO':
            return {...state, fleetInformation: {...state.fleetInformation, fleetProceduresControl: action.data}}
      default: 
        return state;
    }
}

const stateToLocalStorage = (state) => {
    let savedStatte = Object.assign({}, state)
    delete savedStatte.monthlyOffers
    delete savedStatte.annualOffers
    PurchaseFlowSessionService.setItem(OFFERS_HISTORY_KEY, JSON.stringify(savedStatte))
}

const reducer = (state, action) => {
    console.log('BuyingInsuranceContext state', state, action)
    let newState = calculateState(state, action)
    stateToLocalStorage(newState)
    return { ...newState, ...resetableState }
};


export const BuyingInsuranceContextStore = React.createContext(defaultState)
BuyingInsuranceContextStore.displayName = 'BuyingInsuranceContextStore';

const BuyingInsuranceContext = (props) => {
    const profileContext = React.useContext(UserProfileContextStore)
    const [state, dispatch] = useReducer(reducer, defaultState, () => {
        let savedState = {}
        
        // Initialize from local storage
        if(PurchaseFlowSessionService.getItem(OFFERS_HISTORY_KEY)) {
            savedState = JSON.parse(PurchaseFlowSessionService.getItem(OFFERS_HISTORY_KEY))
            savedState.subscriptionStartTime = new Date(savedState.subscriptionStartTime)
            if(savedState.subscriptionStartTime < new Date() || !savedState.subscriptionStartTime) {
                savedState.subscriptionStartTime = new Date()
            }
            if(!parseInt(savedState.maximumUasInAir))
                savedState.maximumUasInAir = 1
        }

        return Object.assign({}, defaultState, savedState)
    })

    const loadOffers = async () => {
        let startTime = state.subscriptionStartTime
        if(startTime && isToday(startTime))
            startTime = undefined
        else{
            let dateObj = new Date(startTime);
            startTime = new Date(Date.UTC(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), 0, 1, 0, 0));
        }

        let res = undefined
        if(SessionService.isLoggedIn()) {
            if(SessionService.isAdmin() && state.liabilitySpecialPrice.enabled == 'yes')
                res = await InsuranceApi.loadOffers(startTime, state.maximumUasInAir, state.coverageType, [], [],
                    state.operators, state.personalInjuryCoverage, state.medicalExpense, state.worldWideCoverage, state.indoorCoverage,state.numberOfDronesOperate, state.fleetInformation, state.liabilitySpecialPrice)
            else
                res = await InsuranceApi.loadOffers(startTime, state.maximumUasInAir, state.coverageType, [], [],
                     state.operators, state.personalInjuryCoverage, state.medicalExpense, state.worldWideCoverage, state.indoorCoverage,state.numberOfDronesOperate, state.fleetInformation)
        }
        else {
            let drones = []
            let equipment = []
            let savedState = JSON.parse(PurchaseFlowSessionService.getItem(ONGOING_PURCHASE))
            if(savedState) {
                drones = savedState.drones
                equipment = savedState.equipment
            }
            
            res = await InsuranceApi.loadOffers(startTime, state.maximumUasInAir, state.coverageType, drones, equipment,
                 state.operators, state.personalInjuryCoverage, state.medicalExpense, state.worldWideCoverage, state.indoorCoverage,state.numberOfDronesOperate, state.fleetInformation)
            
        }
            
        if(res.ok) {
            dispatch({type: 'OFFERS_LOADED', monthlyOffers: res.monthlyOffers.insuranceOptions, annualOffers: res.annualOffers.insuranceOptions})
        }
        else {
            if(res.data.code == 400 && 
                res.data.messages && 
                res.data.messages.offers &&
                res.data.messages.offers.findIndex(t => t == "Indoor coverage is not yet supported in your location.") != -1)
                    dispatch({type: OFFERS_ACTIOS.SET_INDOOR_COVERAGE, data: { included: false, description: state.indoorCoverage.description }})

            if(res.data.code == 400 && 
                res.data.messages && 
                res.data.messages.offers &&
                res.data.messages.offers.findIndex(t => t.startsWith("Scheduling is not available after")) != -1)
                    dispatch({type: OFFERS_ACTIOS.RESET_START_TIME, data: {}})

            EventEmitterService.dispatch(EventKey.ShowError, res)
        }
    }

    useEffect(() => {
        !SessionService.isBroker() && loadOffers()

        return function cleanup() {
            //TODO: Deleting the local storage only for broker (?)
            if(SessionService.isBroker())
            PurchaseFlowSessionService.removeItem(OFFERS_HISTORY_KEY)
        }

    }, [])

    useEffect(() => {
        let id = EventEmitterService.subscribe(EventKey.HULL_ITEM_CHANGED, () => {
            loadOffers()
        })

        let id2 = EventEmitterService.subscribe(EventKey.USER_LOGGED_IN, () => {
            loadOffers()
        })

        let id3 = EventEmitterService.subscribe(EventKey.FLOW_PROFILE_FINISH, () => {
            // In case State has changed so offers will reflect taxes
            loadOffers()
        })

        let id4 = EventEmitterService.subscribe(EventKey.SESSION_USER_LOGOUT, () => {
            dispatch({type: 'RESET'})
        })

        return function cleanup () {
            EventEmitterService.unsubscribe(EventKey.HULL_ITEM_CHANGED, id)
            EventEmitterService.unsubscribe(EventKey.USER_LOGGED_IN, id2)
            EventEmitterService.unsubscribe(EventKey.FLOW_PROFILE_FINISH, id3)
            EventEmitterService.unsubscribe(EventKey.SESSION_USER_LOGOUT, id4)
        }
    }, [state])

    useEffect(() => {
        loadOffers()
    }, [state.fleetInformation])

    useEffect(() => {
        if(state != defaultState)
        {
            loadOffers()
        }
    }, [state.maximumUasInAir, state.coverageType, state.operators,
         state.subscriptionStartTime, state.personalInjuryCoverage, state.medicalExpense, state.worldWideCoverage,
         state.liabilitySpecialPrice, state.indoorCoverage,state.numberOfDronesOperate])

    useEffect(() => {
        // Reset Starting date after successfull purchase
        let id = EventEmitterService.subscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, () => {
            changeStartingDate(new Date())
        })

        return function cleanup () {
            EventEmitterService.unsubscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, id)
        }
    }, [])

    const changeStartingDate = (startingDate: Date) => {
        dispatch({type: 'CHANGE_STARTING_DATE', startingDate: startingDate})
    }

    const changeNumberOfDronesUserOperate = (value) =>{
        dispatch({type: 'CHANGE_NUMBER_OF_DRONES_OPERATE', value: value, numberOfDronesText: NUMBER_OF_DRONES_QUESTION_ANSWERS[value].value})
    }

    const changeMaximumUAS = (maximumUasInAir) => {
        dispatch({type:'MAXIMUM_UAS_CHANGE', maximumUasInAir: maximumUasInAir})
    }

    const changePersonalInjury = (value) => {
        dispatch({type:'PERSONAL_INJURY_CHANGE', value: value})
    }

    const changeMedicalExpenseLimit = (value) => {
        dispatch({type:'MEDICAL_EXPENSE_LIMIT_CHANGE', value: value})
    }

    const isInFleetFlow = () =>{
        return state.flowType === FLOW_TYPES.FLEET
    }


    const changeCoverageType = (type) => {
        dispatch({type: 'SET_COVERAGE_TYPE', data: type});
    }

    const loadQuote = (quote) => {
        dispatch({type: 'LOAD_QUOTE', data: quote})
    }

    const setSelections = (selections) => {
        dispatch({type: OFFERS_ACTIOS.SET_SELECTIONS, data: selections})
    }

    const setFleetFile = (file) => {
        dispatch({type: 'SET_FLEET_FILE', data: file})
    }

    const setFleetSize = (data) => {
        dispatch({type: 'SET_FlEET_SIZE', data: data})
    }

    const setYearlyFleetSize = (data) => {
        dispatch({type: 'SET_YEARLY_FlEET_SIZE', data: data})
    }

    const setFleetIntendedUser = (data) => {
        dispatch({type: 'SET_FLEET_INTENDED_USE', data: data})
    }

    const loadIntendedUses = async () => {
        const res = await generalApi.getIntendedUses();
        dispatch({type: 'LOAD_INTEDED_USES', data: res.data.intended_uses})
    }

    const setRegularFlow = async () => {
        dispatch({type: 'SET_REGULAR_FLOW'})
    }

    const setFleetFlow = async () => {
        dispatch({type: 'SET_FLEET_FLOW'})
    }

    const setPublishableFleetYesNo = async (flag) => {
        dispatch({type: 'SET_FLEET_PUBLISHABLE_YES_NO', data: flag})
    }

    const setProceduresControlFleetYesNo = async (flag) => {
        dispatch({type: 'SET_FLEET_PROCEDURES_CONTROL_YES_NO', data: flag})
    }
    
    const setPICoverage = (limit) => {
        dispatch({type: 'SET_PI_COVERAGE', data: limit})
    }

    const flipLiabilitySpecialPrice = () => {
        if(state.liabilitySpecialPrice.enabled == "yes")
            dispatch({type: OFFERS_ACTIOS.SET_LIABILITY_SPECIAL_PRICE, data: {enabled: 'no',
             custom_premium: state.liabilitySpecialPrice.custom_premium,
            }})
        else
            dispatch({type: OFFERS_ACTIOS.SET_LIABILITY_SPECIAL_PRICE, data: {enabled: 'yes',
             custom_premium: state.liabilitySpecialPrice.custom_premium,
            }})
    }

    const setLiabilitySpecialPrice = (premium, premiumToken) => {
        dispatch({type: OFFERS_ACTIOS.SET_LIABILITY_SPECIAL_PRICE, data: {enabled: 'yes',
         custom_premium: premium}})
    }

    const flipWorldWideCoverage = (limits, description) => {
        dispatch({type: 'SET_WORLD_WIDE_COVERAGE_LIMITS', data: { limits: limits, desc: description }})
    }

    return (<BuyingInsuranceContextStore.Provider value={{ ...state, setPICoverage, setRegularFlow, setYearlyFleetSize, setPublishableFleetYesNo,setProceduresControlFleetYesNo, setFleetFlow, loadIntendedUses, setSelections, dispatch, setLiabilitySpecialPrice, flipLiabilitySpecialPrice, flipWorldWideCoverage, changeMedicalExpenseLimit,isInFleetFlow, changeCoverageType, changePersonalInjury, changeMaximumUAS,changeNumberOfDronesUserOperate, changeStartingDate, loadQuote, loadOffers, setFleetFile, setFleetIntendedUser, setFleetSize }}>
            {props.children}
        </BuyingInsuranceContextStore.Provider>)   
}

export default BuyingInsuranceContext



