
import * as firebase from "firebase/app";
import "firebase/remote-config"
import "firebase/analytics"
import { USFirebaseServiceInitializer } from "../utilities/config/USFirebaseServiceInitializer";
import { UKFirebaseServiceInitializer } from "../utilities/config/UKFirebaseServiceInitializer";
import { CountryBasedFirebaseInitialize } from "../models/services/CountryBasedFirebaseInitialize";
import { CAFirebaseServiceInitializer } from "../utilities/config/CAFirebaseServiceInitializer";

export const FirebaseConfigKey = {
    FILE_A_CLAIM_URL: "fileAClaimUrl",
    COMMERCIAL_VIEW_STRINGS_JSON: "commercialViewStringsJson",
    GUIDELINES_STRINGS_JSON: "guidelineStringsJson",
    GUIDELINE_KEY_FACTS_URL_HOURLY: "guidelineKeyFactsHourlyUrl",
    GUIDELINE_KEY_FACTS_URL_MONTHLY: "guidelineKeyFactsMonthlyUrl",
    HULL_INSURANCE_AVAILABLE: "hullInsuranceAvailable",
    HULL_ESTIMATED_PREMIUM_FACTOR_STARR:"hullEstimatedPremiumFactorStarr",
    HULL_ESTIMATED_PREMIUM_FACTOR: "hullEstimatedPremiumFactor",
    HULL_ESTIMATED_DEDUCTIBLE_FACTOR_STARR: "hullEstimatedDeductibleFactorStarr",
    HULL_ESTIMATED_DEDUCTIBLE_FACTOR: "hullEstimatedDeductibleFactor",
    HULL_ESTIMATED_PREMIUM_FACTOR_ANNUAL_STARR: "hullEstimatedPremiumFactorAnnualStarr",
    HULL_ESTIMATED_PREMIUM_FACTOR_ANNUAL: "hullEstimatedPremiumFactorAnnual",
    HULL_ESTIMATED_DEDUCTIBLE_FACTOR_ANNUAL_STARR: "hullEstimatedDeductibleFactorAnnualStarr",
    HULL_ESTIMATED_DEDUCTIBLE_FACTOR_ANNUAL: "hullEstimatedDeductibleFactorAnnual",
    HULL_LIABILITY_MINIMUM: "hullLiabilityMinimum",
    TEMPERATURE_SCALE_SIGN: "temperatureScaleSign",
    DIFF_BETWEEN_RATE_APP_REQUEST_MILLISECONDS: "diffBetweenRateAppRequestMilliseconds",
    APP_RATING_BLOCKED: "appRatingBlocked",
    SUCCESS_EVENTS_FOR_RATE_APP: "successEventsForRateApp",
    USER_MUST_BE_COMMERCIAL: "userMustBeCommercial",
    GDPR_REGISTRATION_CONFIRMATION_TEXT: "gdprRegistrationConfirmationText",
    GDPR_REQUIRED: "gdprRequired",
    COUNTRY_AWARENESS_BLOCKED: "countryAwarnessBlocked",
    ADDITIONAL_INSURED_NOTICE: "additionalInsuredNotice",
    FAQ_URL: "faqUrl",
    REFERRAL_PROGRAM_ENABLED: "referralProgramEnabled",
    REFERRAL_PROGRAM_CREDIT_LIMIT: "referralProgramCreditLimit",
    IS_INDOOR_COVERAGE_AVAILABLE: "isIndoorCoverageAvailable",
    PERFORMANCE_MONITORING_ENABLED: "performanceMonitoringEnabled",
    USER_RECORDING_ENABLED: "userRecordingEnabled",
    SMARTLOOK_KEY: "smartlookKey",
    LIABILITY_LIMITS: "liabilityLimits",
    CURRENCY_SYMBOL: "currencySymbol",
    INSURANCE_COMPANY_NAME: "insuranceCompanyName",
    POLICY_EXTENSION_AVAILABLE: "policyExtensionAvailable",
    CONTACT_US_URL: "contactUsUrl",
    AVAILABLE_COUNTRIES: "availableCountries",
    HULL_INSURANCE_AVAILABLE_FOR_MONTHLY: "hullInsuranceAvailableForMonthly",
    HULL_INSURANCE_AVAILABLE_FOR_ANNUAL: "hullInsuranceAvailableForAnnual",
    SHORT_DATE_FORMAT: "shortDateFormat",
    LONG_DATE_FORMAT: "longDateFormat",
    DATE_TIME_FORMAT: "dateFormatDayjs",
    MINIMUN_DRONE_VALUE: "minimumDroneValue",
    MAXIMUM_DRONE_VALUE: "maximumDroneValue",
    MAXIMUM_DRONE_VALUE_FOR_BROKER: "maximumDroneValueForBroker",
    MAXIMUM_DRONE_VALUE_FOR_ADMIN: "maximumDroneValueForAdmin",
    MINIMUN_EQUIPMENT_VALUE: "minimumEquipmentValue",
    MAXIMUM_EQUIPMENT_VALUE: "maximumEquipmentValue",
    MAXIMUM_EQUIPMENT_VALUE_FOR_BROKER: "maximumEquipmentValueForBroker",
    MAXIMUM_EQUIPMENT_VALUE_FOR_ADMIN: "maximumEquipmentValueForAdmin",
    FTUE: "ftue",
    ADMINISTRATIVE_DIVISION_UNAVAILABLE_TEXT: "adminDivisionUnavailableText",
    DEFAULT_LIABILITY: "defaultLiability",
    INSURANCE_OPTIONS_CONFIGURATION: "insuranceOptionsConfiguration",
    UNIT_LENGTH: "unitLength",
    MIN_SAVINGS_DISPLAY: 'minimum_savings_display',
    LIABILITY_COVERAGE_TEXT: 'liabilityCoverageText',
    LIABILITY_COVERAGE_TEXT_STARR: 'liabilityCoverageTextStarr',
    ZIPCODE_REGEX: 'zipcodeRegex',
    RESTRICTED_COUNTRIES: 'restrictedCountries',
    COUNTRY: 'country',
    ANNUAL_AVAILABLE: 'annualAvailable',
    MONTHLY_AVAILABLE: 'monthlyAvailable',
    LIABILITY_DEDUCTIBLE: 'liabilityDeductible',
    REGISTRATION_AVAILABLE: 'registrationAvailable',
    INSURED_LOGIN_AVAILABLE: 'insuredLoginAvailable',
    PAYMENT_REQUIRED_FOR_BROKER: 'paymentRequired',
    DRONE_IMAGE_REQUIRED_FOR_BROKER: 'droneImgRequiredForBroker',
    CLAIM_QUESTION: 'claimQuestion',
    SHOULD_DISPLAY_HULL_ESTIMATIONS: 'shouldDisplayHullEstimations',
    TOTAL_HULL_SUM_INSURED: 'totalHullSumInsured',
    DRONE_TAKEOFF_WEIGHTS: 'droneTakeOffWeights',
    LIABILTY_LIMIT_EXTENSION_AVAILABLE: "liabilityLimitExtensionAvailable",
    PAUSE_POLICY_AVAILABLE: "pasuePolicyAvailable",
    IS_HULL_EDITABLE: "isHullEditable",
    WHATS_COVERED_AVAILABLE: "whatsCoveredAvailable",
    HULL_HEADER_TEXT: "hullHeaderText",
    OPERATOR_EDITING_AVAILABLE: "operatorEditingAvailable",
    SHOW_HULL_PREMIUMS_ON_POLICY_PAGE: "showHullPremiumInPolicy",
    FLIGHT_LOGS_URL: "flightLogsUrl",
    PROPOSAL_LINK_AVAILABLE: "proposalLinkAvailable",
    PERSONAL_INJURY_COVERAGE: "personalInjuryCoverage",
    MEDICAL_EXPENSES: "medicalExpenses",
    SUPPORT_PHONE_NUMBER: 'supportPhoneNumber',
    PRETTY_COUNTRY_NAME: 'prettyCountryName',
    ADDITIONAL_COVERAGE_TOOLTIP: 'additionalCoverageTooltip',
    WORLD_WIDE_TEXT: 'worldwideText',
    MINIMUM_HULL_VALUE_FOR_IMAGE_MANDATORY: 'minimumHullValueForImageMandatory',
    FELONY_QUESTION: "In the last 5 years, have any of the operators",
    FELONY_QUESTION_SECTION: "(a) been cited for violation of any FAA regulations, or*(b) had their pilot's or driver's license suspended, or *(c) been convicted of driving while intoxicated, or*(d) been convicted of any felony charge?",
    INCIDENTS_LOSSES_QUESTION:'In the last 5 years, have you been involved in any aircraft or UAS accidents, incidents or losses?',
    OVERFLIGHT_OF_PEOPLE:'Will all flights be conducted in accordance with applicable regulations, including no overflights of people unless in accordance with the FAA rule for The Operation of Unmanned Aircraft Systems Over People, or with an appropriate waiver?'

}

export let countryBasedConfig: CountryBasedFirebaseInitialize = undefined

if (process.env.REACT_APP_COUNTRY == "US") {
    countryBasedConfig = new USFirebaseServiceInitializer()
}
else if (process.env.REACT_APP_COUNTRY == "UK") {
    countryBasedConfig = new UKFirebaseServiceInitializer()
}
else if (process.env.REACT_APP_COUNTRY == "CA") {
    countryBasedConfig = new CAFirebaseServiceInitializer()
}


export class FirebaseService {

    static async init() {
        return await countryBasedConfig.remoteConfig.fetchAndActivate()
    }

    static getValue(key) {
        return countryBasedConfig.remoteConfig.defaultConfig[key]
        return countryBasedConfig.remoteConfig.getValue(key).asString()
    }

    static getBoolean(key) {
        return Boolean(countryBasedConfig.remoteConfig.defaultConfig[key])
        return countryBasedConfig.remoteConfig.getBoolean(key)
    }

    static getArray(key) {
        try {
            return JSON.parse(this.getValue(key))
        }
        catch(e) {
            return []
        }
    }

    static getObject(key) {
        return this.getArray(key)
    }

    static getNumber(key) {
        return Number(countryBasedConfig.remoteConfig.defaultConfig[key])
        return countryBasedConfig.remoteConfig.getNumber(key)
    }
}